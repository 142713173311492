import { useContext } from "react";
import { useHistory, useParams } from "react-router";
import { PageContent } from "../../../ui/components/PageContent/PageContent";
import ToastContext from "../../../utils/contexts/ToastContext";
import { useTrack } from "../../../utils/hooks/useTrack";
import { LocationForm } from "./LocationForm";
import useAllLocationDetails from "./hooks/useAllLocationDetails";
import { useUpdateLocation } from "./hooks/useUpdateLocation";

interface Props {}

export const EditLocation = (props: Props) => {
  const { locationSlug } = useParams<{ locationSlug: string }>();
  const { data, loading, refetch } = useAllLocationDetails(locationSlug);
  const updateLocation = useUpdateLocation();
  const history = useHistory();
  const { track } = useTrack();

  const { showToast } = useContext(ToastContext);

  const onSubmit = async (updateData: any) => {
    try {
      track({
        event: "Location Update Attempted",
        properties: {
          location: { ...data?.all_location_details },
          updateData,
        },
      });
      await updateLocation({
        variables: {
          input: { ...updateData, slug: locationSlug },
        },
      });

      showToast({
        description: "Location was updated successfully",
        variant: "success",
        seconds: 2,
        onClose: () => {},
      });

      await refetch();
    } catch (err) {
      showToast({
        description: "Location could not be updated",
        variant: "error",
        seconds: 2,
        onClose: () => {},
      });
    }
  };

  if (loading) {
    return null;
  }
  return (
    <PageContent>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex items-center justify-between my-5">
        <div className="flex items-center">
          <h1 className="text-3xl font-semibold text-gray-900 mr-3">
            Editing {data?.all_location_details?.name}
          </h1>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <LocationForm
          onSubmit={onSubmit}
          location={data?.all_location_details}
        />
      </div>
    </PageContent>
  );
};
