import { Controller, useForm } from "react-hook-form";
import { Button, FormInput, FormLabel } from "../../../ui";
import useCurrentLocation from "../../../utils/hooks/useCurrentLocation";

interface Props {
  onSubmitHandler: any;
  locationPrinter?: any;
}

export const LocationPrinterForm = ({
  onSubmitHandler,
  locationPrinter,
}: Props) => {
  const currentLocation = useCurrentLocation();
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="grid grid-cols-2 my-5">
        {!!locationPrinter && (
          <input
            type="hidden"
            value={locationPrinter?.id}
            {...register("printer_id")}
          />
        )}
        <input
          type="hidden"
          value={currentLocation.id}
          {...register("location_id")}
        />
        <div className="col-span-1">
          <FormLabel
            title="Receipt Printer Serial Number"
            htmlFor="external_id"
            information={
              "The serial number for the printer being paired to " +
              currentLocation?.name +
              ". Receipt printers can only be paired to a single location."
            }
          />
          <Controller
            name="external_id"
            defaultValue={!!locationPrinter ? locationPrinter.external_id : ""}
            control={control}
            rules={{ required: true }}
            render={({ field }) => <FormInput type="text" {...field} />}
          />
          <p className="text-red-800">
            {errors?.external_id?.type === "required" &&
              "This field is required"}
          </p>
        </div>
      </div>
      <Button>Save</Button>
    </form>
  );
};
