import { useContext } from "react";
import { useHistory } from "react-router";
import { PageContent } from "../../../ui/components/PageContent/PageContent";
import ToastContext from "../../../utils/contexts/ToastContext";
import { useAddLocation } from "./hooks/useAddLocation";
import { LocationForm } from "./LocationForm";

interface Props {}

export const AddLocation = (props: Props) => {
  const addLocation = useAddLocation();
  const history = useHistory();

  const { showToast } = useContext(ToastContext);

  const onSubmit = async (data: any) => {
    try {
      const response = await addLocation({
        variables: {
          input: data,
        },
      });
      showToast({
        description: "Location was added successfully",
        variant: "success",
        seconds: 2,
        onClose: () => {},
      });
      history.push(`/locations/${response.data.create_location}?tab=brands`);
    } catch (err) {
      showToast({
        description: "Location could not be added",
        variant: "error",
        seconds: 2,
        onClose: () => {},
      });
    }
  };

  return (
    <PageContent>
      <h1 className="text-3xl font-semibold text-gray-900 mr-3">
        New Location
      </h1>
      <LocationForm onSubmit={onSubmit} />
    </PageContent>
  );
};
