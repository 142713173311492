import { useLazyQuery } from "@apollo/client";
import { useCallback } from "react";
import { PublicMenuItem } from "../../../../types";
import { MenuItemDraggable } from "../../../../ui/components/SearchAndDrag/MenuItemDraggable";
import { SearchAndDrag } from "../../../../ui/components/SearchAndDrag/SearchAndDrag";
import useCurrentLocation from "../../../../utils/hooks/useCurrentLocation";
import { OPERATIONS_SEARCH_MENU_ITEMS_QUERY } from "./hooks/useSearchMenuItems";

export const ItemTypes = {
  selectedItem: "selected-item",
  availableItem: "available-item",
};

export interface PlaceholderPublicMenuItem extends PublicMenuItem {
  isPlaceholder?: boolean;
}

interface StoreMenuSearchAndDragProps {
  storeMenuItems: PublicMenuItem[];
  onChange: (val: number[]) => void;
  selectedValues: number[];
  maximumItems?: number;
}

export const StoreMenuSearchAndDrag = ({
  storeMenuItems,
  onChange,
  selectedValues = [],
  maximumItems,
}: StoreMenuSearchAndDragProps) => {
  const currentLocation = useCurrentLocation();
  const [searchMenuItems, { data, loading: isSearchLoading }] = useLazyQuery(
    OPERATIONS_SEARCH_MENU_ITEMS_QUERY,
  );

  const onSearchQueryChange = useCallback(
    (query: string) => {
      searchMenuItems({
        variables: {
          location_id: currentLocation?.id,
          query,
          filters: [],
          filter_active_modifiers: false,
          is_internal: true,
        },
      });
    },
    [currentLocation?.id, searchMenuItems],
  );

  const renderItem = useCallback(
    (item: PublicMenuItem, isPlaceholder: boolean, isDragging: boolean) => (
      <MenuItemDraggable
        item={item}
        isPlaceholder={isPlaceholder}
        isDragging={isDragging}
      />
    ),
    [],
  );

  return (
    <SearchAndDrag
      allItems={storeMenuItems}
      onChange={onChange}
      selectedValues={selectedValues}
      onSearchQueryChange={onSearchQueryChange}
      searchResults={data?.search_menu_items?.items || []}
      isSearchLoading={isSearchLoading}
      renderItem={renderItem}
      maximumItems={maximumItems}
    />
  );
};
