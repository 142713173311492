import { Dispatch, SetStateAction, useContext } from "react";
import { FieldValues } from "react-hook-form";
import { Modal } from "../../../ui";
import ToastContext from "../../../utils/contexts/ToastContext";
import useCurrentLocation from "../../../utils/hooks/useCurrentLocation";
import { useCreatePrepStation } from "./hooks/useCreatePrepStation";
import { PrepStationForm } from "./PrepStationForm";

interface Props {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}
export const AddPrepStationModal = ({ showModal, setShowModal }: Props) => {
  const currentLocation = useCurrentLocation();

  const createPrepStation = useCreatePrepStation();

  const { showToast } = useContext(ToastContext);

  const onSubmitHandler = async (data: FieldValues) => {
    try {
      await createPrepStation({
        variables: { ...data, location_id: parseInt(data?.location_id) },
      });
      showToast({
        description: "Prep Station created",
        seconds: 2,
        variant: "success",
        onClose: () => {},
      });
      setShowModal(false);
    } catch (err) {
      showToast({
        description: (err as any).message,
        seconds: 3,
        variant: "error",
        onClose: () => {},
      });
    }
  };
  return (
    <Modal
      title="New Prep Station"
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <PrepStationForm
        currentLocation={currentLocation}
        onSubmitHandler={onSubmitHandler}
      />
    </Modal>
  );
};
