import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import {
  Button,
  FormInput,
  FormLabel,
  FormOption,
  FormSelect,
  Modal,
} from "../../ui";
import ToastContext from "../../utils/contexts/ToastContext";
import { useAllStores } from "../Menus/hooks/useAllStores";
import { useLocations } from "../Orders/hooks/useLocations";
import useCreateCustomHours from "./hooks/useCreateCustomHours";

interface Props {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  defaultDate?: Date;
  store?: any;
}

export const CustomHoursCreateModal = ({
  showModal,
  setShowModal,
  defaultDate,
  store,
}: Props) => {
  const { control, reset, watch, handleSubmit } = useForm();
  const { data: locationsData, loading: locationsLoading } = useLocations();
  const { data: storeData, loading: storeLoading } = useAllStores();
  const { createCustomHours } = useCreateCustomHours();
  const { showToast } = useContext(ToastContext);

  const watchClosureType = watch("closure_type");

  useEffect(() => {
    reset();
  }, []);

  const padNumber = (number: any) => {
    return ("0" + number.toString()).slice(-2);
  };

  if (locationsLoading || storeLoading) {
    return null;
  }

  const onSubmitHandler = async (data: any) => {
    const [year, month, day] = data.custom_hours_date.split("-");
    let stores;

    if (!!store) {
      stores = [{ id: store.id }];
    } else {
      const affected_locations = data.affected_locations.map(
        (affected_location: any, index: number) => affected_location.value,
      );

      stores = storeData.all_stores.filter(
        (store: any, index: number) => store.is_active,
      );

      if (!affected_locations.includes("all")) {
        stores = stores.filter((store: any, index: number) =>
          affected_locations.includes(store.location.id),
        );
      }
    }

    const transformedData = {
      year: parseInt(year),
      month: parseInt(month),
      day: parseInt(day),
      store_ids: stores.map((store: any) => store.id),
      hours: JSON.stringify(
        data.closure_type === "custom_open_times"
          ? [
              {
                start_day: 0,
                end_day:
                  parseInt(data.end_time.split(":")[0]) <
                  parseInt(data.start_time.split(":")[0])
                    ? 1
                    : 0,
                start_time: data.start_time,
                end_time: data.end_time,
              },
            ]
          : [],
      ),
    };

    try {
      await createCustomHours({
        variables: { input: transformedData },
      });
      showToast({
        description: "Custom Hours have been created.",
        variant: "success",
        seconds: 2,
        onClose: () => {},
      });
      setShowModal(false);
    } catch (err) {
      showToast({
        description: "Custom Hours could not be created. Please try again",
        variant: "error",
        seconds: 2,
        onClose: () => {},
      });
    }
  };

  const affectedLocationsOptions = [
    {
      value: "all",
      label: "All Locations",
    },
  ].concat(
    locationsData.locations.map((location: any, index: number) => ({
      value: location.id,
      label: location.name,
    })),
  );

  const tranformedDefaultDate = !!defaultDate
    ? `${defaultDate.getFullYear()}-${padNumber(
        defaultDate.getMonth() + 1,
      )}-${padNumber(defaultDate.getDate())}`
    : "";
  const title = !!store
    ? `Custom Closure for ${store.brand.name} in ${store.location.name}`
    : "Create Custom Hours";

  return (
    <Modal title={title} showModal={showModal} setShowModal={setShowModal}>
      <div className="my-5">
        <div className="rounded-md bg-yellow-50 p-4 my-5">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">
                Local Kitchens Only
              </h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>
                  The custom hours you are creating are only for the Local
                  Kitchens website. You will still need to add these to our
                  third parties (UberEats and DoorDash) for affected stores.
                </p>
              </div>
            </div>
          </div>
        </div>
        {store && (
          <div className="rounded-md bg-yellow-50 p-4 my-5">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">
                  {store.brand.name} in {store.location.name}
                </h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <p>
                    This will only create a custom closure for{" "}
                    {store.brand.name} in {store.location.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="grid grid-cols-12 gap-6 items-center">
            <div className="col-span-4">
              <FormLabel title="Date" htmlFor="" />
              <Controller
                name="custom_hours_date"
                control={control}
                defaultValue={tranformedDefaultDate}
                rules={{ required: true }}
                render={({ field }) => <FormInput type="date" {...field} />}
              />
            </div>
            {!store && (
              <div className="col-span-4">
                <FormLabel
                  title="Affected Locations"
                  htmlFor="affected_locations"
                />
                <Controller
                  name="affected_locations"
                  control={control}
                  defaultValue={[{ value: "all", label: "All Locations" }]}
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <Select
                        options={affectedLocationsOptions}
                        isMulti
                        isSearchable={false}
                        {...field}
                      />
                    );
                  }}
                />
              </div>
            )}
            <div className="col-span-4">
              <FormLabel
                title="Type"
                htmlFor="closure_type"
                information='If this is a temporary closure, please select "All Day" for the given location, you can then delete it when you are ready to reopen.'
              />
              <Controller
                name="closure_type"
                defaultValue="all_day"
                control={control}
                rules={{ required: false }}
                render={({ field }) => {
                  return (
                    <FormSelect {...field}>
                      <FormOption title="All Day" value="all_day" />
                      <FormOption
                        title="Custom Hours"
                        value="custom_open_times"
                      />
                    </FormSelect>
                  );
                }}
              />
            </div>
          </div>
          {watchClosureType === "custom_open_times" && (
            <>
              <div className="mt-10">
                <div className="block text-base font-medium text-gray-700">
                  Custom Opening
                </div>
                <div className="grid gap-6 grid-cols-2">
                  <div className="text-md text-center">Open</div>
                  <div className="self-center text-md text-center">Close</div>
                  <div>
                    <Controller
                      name={`start_time`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <FormInput type="time" {...field} />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name={`end_time`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <FormInput type="time" {...field} />
                      )}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="mt-5">
            <Button className="mr-2">Save</Button>
            <Button
              type="button"
              backgroundColor="bg-gray-500"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
