import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { Button, Table, TableData, TableRow } from "../../../ui";
import { PageContent } from "../../../ui/components/PageContent/PageContent";
import { displayAsPhoneNumber } from "../../../utils/formatters";
import { useLocations } from "../../Orders/hooks/useLocations";
import { useSyncRevenueCenters } from "./hooks/useSyncRevenueCenters";
import Loader from "../../../ui/components/Loader/Loader";

export default function Locations() {
  const { onSyncRevenueCenters, loading: revenueCenterSyncInProgress } =
    useSyncRevenueCenters();
  const { data: locationsData, loading: locationsLoading } = useLocations();

  const history = useHistory();

  if (locationsLoading) {
    return null;
  }

  return (
    <PageContent>
      <Loader loading={revenueCenterSyncInProgress} />
      <div className="flex items-center justify-between">
        <h1 className="text-3xl font-semibold text-gray-900 mr-3">Locations</h1>
        <div className="ml-auto flex items-center">
          <Button
            className="flex items-center"
            type="button"
            onClick={() => onSyncRevenueCenters({ location_id: null })}
          >
            Sync Revenue Centers
          </Button>
          <Button
            className="flex items-center ml-3"
            type="button"
            onClick={() => history.push("/locations/new")}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            New Location
          </Button>
        </div>
      </div>

      <Table
        headers={[
          {
            text: "Status",
            information:
              'Once a location is live, you will want the status to be "Visible". When onboarding, make sure the status is "Not Visible", as you will not want guests to be able to see it in the location changer',
          },
          "Name",
          "Address",
          "Phone Number",
        ]}
      >
        {locationsData?.locations.map((item: any, index: number) => {
          return (
            <TableRow
              key={item.id}
              onClick={() => history.push(`/locations/${item.slug}?tab=brands`)}
            >
              <TableData>
                <span
                  className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-md font-medium border ${
                    item.is_active
                      ? "bg-green-100 text-green-800 border-green-800"
                      : "bg-gray-100 text-gray-800 border-gray-800"
                  }`}
                >
                  {`${item.is_active ? "Visible" : "Not Visible"}`}
                </span>
              </TableData>
              <TableData>
                <span className="mr-2">{item.name}</span>
              </TableData>
              <TableData>
                <p>
                  {item.street}, {item.state} {item.zipcode}
                </p>
              </TableData>
              <TableData>
                <p>{displayAsPhoneNumber(item.phone_number)}</p>
              </TableData>
            </TableRow>
          );
        })}
      </Table>
    </PageContent>
  );
}
