import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { FormLabel, Modal } from "../../ui";
import dayjs from "../../utils/dayjs";
import { useAllStores } from "../Menus/hooks/useAllStores";
import { useLocations } from "../Orders/hooks/useLocations";
import useCreateCustomHours from "./hooks/useCreateCustomHours";
import { useCustomHours } from "./hooks/useCustomHours";
import useDeleteCustomHours from "./hooks/useDeleteCustomHours";

interface Props {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  selectedData: any;
  refetch: any;
}

export const convertToStandard = (timeString: string) => {
  const [hours, minutes] = timeString.split(":");

  const integerHour = parseInt(hours);

  if (integerHour < 12) {
    return `${integerHour}:${minutes} AM`;
  } else if (integerHour === 12) {
    return `${integerHour}:${minutes} PM`;
  }
  return `${integerHour - 12}:${minutes} PM`;
};

const displayHours = (closure: any) => {
  if (!closure) {
    return null;
  }
  const dateOfClosure = dayjs(closure.override_date).format("LL");
  const hours = JSON.parse(closure.hours);

  if (hours.length === 0) {
    return (
      <p>
        The following stores are closed{" "}
        <span className="font-bold">ALL DAY</span> on{" "}
        <span className="font-bold">{dateOfClosure}</span>
      </p>
    );
  }
  return (
    <>
      <p>
        The following stores have custom hours on{" "}
        <span className="font-bold">{dateOfClosure}</span>
      </p>
      {hours.map((interval: any, index: number) => (
        <div className="grid w-32 grid-cols-2">
          <p>Open:</p>
          <p className="justify-self-end">
            {convertToStandard(interval.start_time)}
          </p>
          <p>Close:</p>
          <p className="justify-self-end">
            {convertToStandard(interval.end_time)}
          </p>
        </div>
      ))}
    </>
  );
};

export const CustomHoursDetailModal = ({
  showModal,
  setShowModal,
  selectedData,
}: Props) => {
  const { data: customHoursData, refetch } = useCustomHours();
  const { data: locationsData, loading: locationsLoading } = useLocations();
  const { data: storesData, loading: storesLoading } = useAllStores();
  const { control } = useForm();

  const { createCustomHours } = useCreateCustomHours();
  const { deleteCustomHours } = useDeleteCustomHours();

  const { selectedDateStr, selectedHours } = selectedData;

  useEffect(() => {
    refetch();
  }, []);

  const allCustomHours = useMemo(
    () => customHoursData.custom_hours,
    [customHoursData],
  );
  const currentClosures = useMemo(
    () =>
      allCustomHours.filter((custom_hours: any, index: number) => {
        const currentOverrideDate = new Date(
          custom_hours.override_date,
        ).toString();
        const currentCustomHours = custom_hours.hours;
        return (
          currentOverrideDate === new Date(selectedDateStr).toString() &&
          currentCustomHours === selectedHours
        );
      }),
    [allCustomHours, selectedDateStr, selectedHours],
  );

  useEffect(() => {
    if (currentClosures.length === 0) {
      setShowModal(false);
    }
  }, [currentClosures]);

  const locationsAffected = useMemo(
    () =>
      Array.from(
        new Set(
          allCustomHours.map(
            (custom_hours: any, index: number) => custom_hours.location_id,
          ),
        ),
      ),
    [allCustomHours],
  );

  if (locationsLoading || storesLoading) {
    return null;
  }

  const onChangeHandler = async (_value: any, actionMeta: any) => {
    if (actionMeta.action === "remove-value") {
      await deleteCustomHours({
        variables: { custom_hours_ids: [actionMeta.removedValue.closure_id] },
      });
    } else if (actionMeta.action === "select-option") {
      const hours = currentClosures[0].hours;
      const overrideDate = new Date(currentClosures[0].override_date);

      const transformedData = {
        year: overrideDate.getFullYear(),
        // add one because the numbering by dayjs is 0-11
        month: overrideDate.getMonth() + 1,
        day: overrideDate.getDate(),
        store_ids: [actionMeta.option.value],
        hours: hours,
      };

      await createCustomHours({
        variables: { input: transformedData },
      });
    } else if (actionMeta.action === "clear") {
      await deleteCustomHours({
        variables: {
          custom_hours_ids: actionMeta.removedValues.map(
            (removedValue: any) => removedValue.closure_id,
          ),
        },
      });
    }
    refetch();
  };

  const locationsMap = locationsData.locations.reduce(
    (prevValue: any, currValue: any) => ({
      ...prevValue,
      [currValue.id]: currValue.name,
    }),
    {},
  );

  const storesMap = storesData.all_stores.reduce(
    (prevValue: any, currValue: any) => ({
      ...prevValue,
      [currValue.id]: currValue,
    }),
    {},
  );
  return (
    <Modal
      title="Custom Hours"
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <div>
        <div className="mb-5">{displayHours(currentClosures[0])}</div>

        <div className="rounded-md bg-blue-50 p-4 my-5">
          <div className="flex items-center">
            <InformationCircleIcon
              className="h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
            <p className="ml-3 text-sm text-blue-700">
              Changes are stored automatically. You can close this modal when
              ready.
            </p>
          </div>
        </div>

        <div className="rounded-md bg-yellow-50 p-4 my-5">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">
                Local Kitchens Only
              </h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>
                  The custom hours you are creating are only for the Local
                  Kitchens website. You will still need to add these to our
                  third parties (UberEats and DoorDash) for affected stores.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          {locationsAffected.map((location_id: any, index: number) => {
            const currentLocation = locationsMap[location_id];

            const defaultBrands = currentClosures
              .filter((closure: any) => closure.location_id === location_id)
              .map((closure: any) => ({
                value: closure.store_id,
                label: storesMap[closure.store_id].brand.name,
                closure_id: closure.id,
                store_id: closure.store_id,
              }));

            const options = storesData.all_stores
              .filter((store: any) => store.location.id === location_id)
              .map((store: any) => ({
                value: store.id,
                label: store.brand.name,
                store_id: store.store_id,
              }));

            return (
              <div className="grid grid-cols-6 gap-6" key={location_id}>
                <p>{currentLocation}</p>
                <div className="col-span-5 mb-5">
                  <FormLabel title="Affected Brands" htmlFor="" />
                  <Controller
                    name={`${currentLocation}_affected_brands`}
                    control={control}
                    // defaultValue={defaultBrands}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          value={defaultBrands}
                          isMulti
                          onChange={onChangeHandler}
                          options={options}
                          isSearchable={false}
                        />
                      );
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};
