import {
  faCog,
  faLink,
  faPause,
  faPencilAlt,
  faPlay,
  faPlus,
  faPrint,
  faSync,
  faTablet,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useHistory, useRouteMatch } from "react-router";
import { Button } from "../../../ui";
import DropdownButtons from "../../../ui/components/DropdownButtons/DropdownButtons";
import Loader from "../../../ui/components/Loader/Loader";
import { PageContent } from "../../../ui/components/PageContent/PageContent";
import useCurrentLocation from "../../../utils/hooks/useCurrentLocation";
import useQueryParams from "../../../utils/hooks/useQueryParams";
import { useAllStores } from "../../Menus/hooks/useAllStores";
import { useLocations } from "../../Orders/hooks/useLocations";
import { AddLocationAccountModal } from "../LocationAccounts/AddLocationAccountModal";
import { LocationAccounts } from "../LocationAccounts/LocationAccounts";
import { LocationHours } from "../LocationHours/LocationHours";
import { AddLocationPrinterModal } from "../LocationPrinters/AddLocationPrinterModal";
import { AddPrepStationModal } from "../PrepStations/AddPrepStationModal";
import { ConfigurePrepStationModal } from "../PrepStations/ConfigurePrepStationModal";
import { PrepStations } from "../PrepStations/PrepStations";
import { BrandsTable } from "./BrandsTable";
import { ConfirmPauseModal } from "./ConfirmPauseModal";
import { ConfirmResumeModal } from "./ConfirmResumeModal";
import { CreateLocationEditorialModuleModal } from "./components/CreateLocationEditorialModuleModal";
import {
  EDITORIAL_MODULE_QUERY,
  LocationEditorialModule,
} from "./components/LocationEditorialModules";
import LocationFeaturedItems from "./components/LocationFeaturedItems";
import LocationTab from "./components/LocationTab";
import { useCreateEditorialModule } from "./components/hooks/useCreateEditorialModule";
import { useOperationMenuItems } from "./components/hooks/useOperationsMenuItems";
import { useSyncRevenueCenters } from "./hooks/useSyncRevenueCenters";

type Tab =
  | "brands"
  | "prep_stations"
  | "location_hours"
  | "linked_accounts"
  | "editorial_module"
  | "featured_items";

const TABS: { key: Tab; text: string }[] = [
  {
    key: "brands",
    text: "Brands",
  },
  {
    key: "prep_stations",
    text: "Prep Stations & Printers",
  },
  {
    key: "location_hours",
    text: "Location Hours",
  },
  {
    key: "linked_accounts",
    text: "Linked Accounts",
  },
  {
    key: "editorial_module",
    text: "Menu Modules",
  },
  {
    key: "featured_items",
    text: "Featured Items",
  },
];

export default function LocationDetail() {
  const history = useHistory();
  const { params } = useQueryParams();
  const { url } = useRouteMatch();
  const [showAddPrepStationModal, setShowAddPrepStationModal] =
    useState<boolean>(false);
  const [showLocationAccountModal, setShowLocationAccountModal] =
    useState<boolean>(false);
  const [showConfirmResumeModal, setShowConfirmResumeModal] =
    useState<boolean>(false);
  const [showConfirmPauseModal, setShowConfirmPauseModal] =
    useState<boolean>(false);
  const [showAddPrinterModal, setShowAddPrinterModal] =
    useState<boolean>(false);
  const [showCreateEditorialModuleModal, setShowCreateEditorialModuleModal] =
    useState<boolean>(false);

  const currentLocation = useCurrentLocation();
  const createEditorialModuleMutation = useCreateEditorialModule();
  const { data: menu_items } = useOperationMenuItems(currentLocation?.id);
  const { loading: locationsLoading } = useLocations();
  const { data: allStoresData, loading: allStoresLoading } = useAllStores();
  const [showConfigurePrepStationModal, setShowConfigurePrepStationModal] =
    useState<boolean>(false);

  const { onSyncRevenueCenters, loading: revenueCenterSyncInProgress } =
    useSyncRevenueCenters();
  const storesAtLocation = useMemo(() => {
    if (currentLocation && allStoresData) {
      return allStoresData.all_stores
        .filter((store: any) => store.location.id === currentLocation.id)
        .sort((store1: any, store2: any) => {
          if (store1.ordinal !== store2.ordinal) {
            return store1.ordinal - store2.ordinal;
          }

          if (store1.is_active !== store2.is_active) {
            return store1.is_active ? -1 : 1;
          }

          return store1.brand.name.localeCompare(store2.brand.name);
        });
    }
    return null;
  }, [currentLocation, allStoresData]);

  const onCreateEditorialModule = async (data: any) => {
    await createEditorialModuleMutation({
      variables: {
        input: { ...data, location_id: currentLocation.id },
      },
      refetchQueries: [
        {
          query: EDITORIAL_MODULE_QUERY,
          variables: { location_id: currentLocation.id },
        },
      ],
    });
  };

  if (locationsLoading || allStoresLoading) {
    return null;
  }

  const ComponentByTab = (tab: Tab) => {
    switch (tab) {
      case "brands":
        return <BrandsTable stores={storesAtLocation} />;
      case "prep_stations":
        return <PrepStations />;
      case "location_hours":
        return <LocationHours stores={storesAtLocation} />;
      case "linked_accounts":
        return <LocationAccounts />;
      case "editorial_module":
        return (
          <LocationEditorialModule
            location_id={currentLocation.id}
            menu_items={menu_items}
            setShowModal={setShowCreateEditorialModuleModal}
          />
        );
      case "featured_items":
        return <LocationFeaturedItems location={currentLocation} />;
      default:
        return null;
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <PageContent>
        <Loader loading={revenueCenterSyncInProgress} />
        <div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <h1 className="text-3xl font-semibold text-gray-900 mr-3">
                {currentLocation.name}
              </h1>
              <Button
                onClick={() =>
                  history.push(`/locations/${currentLocation.slug}/edit`)
                }
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </div>
            <div>
              <DropdownButtons
                mainText="Actions"
                items={[
                  {
                    children: (
                      <div className="flex items-center">
                        <div className="w-8">
                          <FontAwesomeIcon icon={faPlus} />
                        </div>
                        <p>Add Brand to {currentLocation.name}</p>
                      </div>
                    ),
                    onClick: () =>
                      history.push(
                        `/locations/${currentLocation.slug}/add-brand`,
                      ),
                  },
                  {
                    children: (
                      <div className="flex items-center">
                        <div className="w-8">
                          <FontAwesomeIcon icon={faTablet} />
                        </div>
                        <p>Add Prep Station</p>
                      </div>
                    ),
                    onClick: () => setShowAddPrepStationModal(true),
                  },
                  {
                    children: (
                      <div className="flex items-center">
                        <div className="w-8">
                          <FontAwesomeIcon icon={faCog} />
                        </div>
                        <p>Configure Prep Stations</p>
                      </div>
                    ),
                    onClick: () => setShowConfigurePrepStationModal(true),
                  },
                  {
                    children: (
                      <div className="flex items-center">
                        <div className="w-8">
                          <FontAwesomeIcon icon={faPrint} />
                        </div>
                        <p>Add Receipt Printer</p>
                      </div>
                    ),
                    onClick: () => setShowAddPrinterModal(true),
                  },
                  {
                    children: (
                      <div className="flex items-center">
                        <div className="w-8">
                          <FontAwesomeIcon icon={faLink} />
                        </div>
                        <p>Link New Account</p>
                      </div>
                    ),
                    onClick: () => setShowLocationAccountModal(true),
                  },
                  {
                    children: (
                      <div className="flex items-center">
                        <div className="w-8">
                          <FontAwesomeIcon icon={faPause} />
                        </div>
                        <p>Pause Ordering</p>
                      </div>
                    ),
                    onClick: () => setShowConfirmPauseModal(true),
                  },
                  {
                    children: (
                      <div className="flex items-center">
                        <div className="w-8">
                          <FontAwesomeIcon icon={faPlay} />
                        </div>
                        <p>Resume Ordering</p>
                      </div>
                    ),
                    onClick: () => setShowConfirmResumeModal(true),
                  },
                  {
                    children: (
                      <div className="flex items-center">
                        <div className="w-8">
                          <FontAwesomeIcon icon={faPlus} />
                        </div>
                        <p>Add Menu module</p>
                      </div>
                    ),
                    onClick: () => {
                      if (params.get("tab") === "editorial_module") {
                        setShowCreateEditorialModuleModal(true);
                      } else {
                        history.push(`${url}?tab=editorial_module`);
                        setTimeout(() => {
                          setShowCreateEditorialModuleModal(true);
                        }, 300);
                      }
                    },
                  },
                  {
                    children: (
                      <div className="flex items-center">
                        <div className="w-8">
                          <FontAwesomeIcon icon={faSync} />
                        </div>
                        <p>Sync Revenue Centers</p>
                      </div>
                    ),
                    onClick: () =>
                      onSyncRevenueCenters({ location_id: currentLocation.id }),
                  },
                ]}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <p>
              {currentLocation.street}, {currentLocation.state}{" "}
              {currentLocation.zipcode}
            </p>
          </div>
          <nav className="my-4 flex space-x-4" aria-label="Tabs">
            {TABS.map((tab) => (
              <LocationTab
                key={`tab-${tab.key}`}
                isSelected={params.get("tab") === tab.key}
                href={`${url}?tab=${tab.key}`}
                text={tab.text}
              />
            ))}
          </nav>
        </div>
        {ComponentByTab(params.get("tab") as Tab)}
        <AddPrepStationModal
          showModal={showAddPrepStationModal}
          setShowModal={setShowAddPrepStationModal}
        />
        {showConfigurePrepStationModal && (
          <ConfigurePrepStationModal
            showModal={showConfigurePrepStationModal}
            setShowModal={setShowConfigurePrepStationModal}
          />
        )}
        <AddLocationPrinterModal
          showModal={showAddPrinterModal}
          setShowModal={setShowAddPrinterModal}
        />
        <AddLocationAccountModal
          showModal={showLocationAccountModal}
          setShowModal={setShowLocationAccountModal}
        />
        <ConfirmResumeModal
          showModal={showConfirmResumeModal}
          setShowModal={setShowConfirmResumeModal}
          stores={storesAtLocation}
        />
        <ConfirmPauseModal
          showModal={showConfirmPauseModal}
          setShowModal={setShowConfirmPauseModal}
          stores={storesAtLocation}
        />
        <CreateLocationEditorialModuleModal
          showModal={showCreateEditorialModuleModal}
          setShowModal={setShowCreateEditorialModuleModal}
          onCreateEditorialModule={onCreateEditorialModule}
          menu_items={menu_items}
        />
      </PageContent>
    </DndProvider>
  );
}
