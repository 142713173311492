import { useQuery, gql } from "@apollo/client";

export const OPERATIONS_LOCATIONS_QUERY = gql`
  query OPERATIONS_LOCATIONS_QUERY {
    locations {
      id
      name
      slug
      street
      state
      zipcode
      phone_number
      is_active
    }
  }
`;

export const useLocations = () => {
  const { data, error, loading, refetch } = useQuery(
    OPERATIONS_LOCATIONS_QUERY,
    {
      fetchPolicy: "cache-and-network",
    },
  );
  return { data, error, loading, refetch };
};
