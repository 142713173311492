import { Dispatch, SetStateAction, useContext } from "react";
import { Modal } from "../../../ui";
import ToastContext from "../../../utils/contexts/ToastContext";
import useCurrentLocation from "../../../utils/hooks/useCurrentLocation";
import { useUpdatePrepStation } from "./hooks/useUpdatePrepStation";
import { PrepStationForm } from "./PrepStationForm";

interface Props {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  prepStation: any;
}

export const EditPrepStationModal = ({
  showModal,
  setShowModal,
  prepStation,
}: Props) => {
  const currentLocation = useCurrentLocation();
  const updatePrepStation = useUpdatePrepStation();
  const { showToast } = useContext(ToastContext);

  const onSubmitHandler = async (data: any) => {
    try {
      await updatePrepStation({
        variables: {
          ...data,
          location_id: parseInt(data?.location_id),
          prep_station_id: parseInt(data?.prep_station_id),
          prep_station_type_id: parseInt(data?.prep_station_type_id),
        },
      });
      showToast({
        description: "Prep Station Updated",
        seconds: 2,
        variant: "success",
        onClose: () => {},
      });
      setShowModal(false);
    } catch (err) {
      showToast({
        description: (err as any).message,
        seconds: 3,
        variant: "error",
        onClose: () => {},
      });
    }
  };

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      title={`Editing ${prepStation.name}`}
    >
      <PrepStationForm
        currentLocation={currentLocation}
        onSubmitHandler={onSubmitHandler}
        prepStation={prepStation}
      />
    </Modal>
  );
};
