import { useMutation, gql } from "@apollo/client";

const ADD_LOCATION_QUERY = gql`
  mutation ADD_LOCATION_QUERY($input: AddLocationInput) {
    create_location(input: $input)
  }
`;

export const useAddLocation = () => {
  const [addLocation] = useMutation(ADD_LOCATION_QUERY);

  return addLocation;
};
