import { TrashIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";
import {
  LIST_LOCATIONS,
  useListLocationsQuery,
  useSyncLocationsQuery,
} from "opsapi/hooks/operations/locations";
import { useContext, useState } from "react";
import { Table, TableData, TableRow } from "../../../../ui";
import Loader from "../../../../ui/components/Loader/Loader";
import ToastContext from "../../../../utils/contexts/ToastContext";
import { FlatButton } from "../../_components/FlatButton";
import { DeleteLocationDialog } from "../components/DeleteLocationDialog";

export const ListLocations = () => {
  const queryClient = useQueryClient();
  const { showToast } = useContext(ToastContext);
  const [includeDeleted, setIncludeDeleted] = useState(false);
  const locationParams = {
    params: { query: { include_deleted: includeDeleted } },
  };
  const { data: locations, isLoading: isLocationsLoading } =
    useListLocationsQuery(locationParams);

  const { mutate: syncLocations, isPending: isSyncingPending } =
    useSyncLocationsQuery({
      reactQuery: {
        onSuccess: (data) => {
          showToast({
            description: `Result: ${JSON.stringify(data)}`,
            variant: "success",
            seconds: 5,
          });
          queryClient.invalidateQueries({
            queryKey: [LIST_LOCATIONS],
          });
        },
        onError: (error) => {
          showToast({
            description: error.message,
            variant: "error",
            seconds: 10,
          });
        },
      },
    });
  const [showDeleteModal, setShowDeleteModal] = useState<string | null>(null);
  const tableHeaders = ["Id", "Name", "State", "Actions"];

  const toggleIncludeDeleted = () => {
    setIncludeDeleted((prev) => !prev);
  };

  if (isLocationsLoading) {
    return <Loader loading={true} />;
  }

  if (!locations) {
    return <div>No locations found</div>;
  }

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-3xl font-semibold text-gray-900">Locations</h1>
        <div className="flex items-center gap-2">
          <button className="underline" onClick={() => toggleIncludeDeleted()}>
            {includeDeleted ? "Hide" : "Show"} deleted
          </button>
          <FlatButton
            onClick={() => syncLocations({})}
            loading={isSyncingPending}
          >
            Sync
          </FlatButton>
        </div>
      </div>

      <div className="flex w-full h-full">
        <Table headers={tableHeaders}>
          {locations.map((location, idx) => (
            <TableRow
              key={location.slug}
              rowColor={idx % 2 ? "bg-white" : "bg-gray-50"}
            >
              <TableData>{location.id}</TableData>
              <TableData className="w-full">{location.name}</TableData>
              <TableData>{location.state}</TableData>
              <TableData className="flex gap-1">
                {location.deleted_at ? (
                  <TrashIcon title="Deleted" className="h-5 w-5 text-red-500" />
                ) : (
                  <TrashIcon
                    title="Delete"
                    className="h-5 w-5 cursor-pointer hover:text-red-500 hidden"
                    onClick={() => setShowDeleteModal(location.slug)}
                  />
                )}

                <DeleteLocationDialog
                  showModal={showDeleteModal === location.slug}
                  hideModal={() => setShowDeleteModal(null)}
                  location={location}
                />
              </TableData>
            </TableRow>
          ))}
        </Table>
      </div>
    </div>
  );
};
