import { useMemo } from "react";
import { useParams } from "react-router";
import { useLocations } from "../../features/Orders/hooks/useLocations";

export default function useCurrentLocation() {
  const { data: locationsData } = useLocations();
  const { locationSlug } = useParams<{ locationSlug: string }>();

  const currentLocation = useMemo(() => {
    if (locationsData) {
      return locationsData.locations.find(
        (location: any) => location.slug === locationSlug,
      );
    }
    return {};
  }, [locationsData]);
  return currentLocation;
}
