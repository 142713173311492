import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import Select from "react-select";
import { FormLabel, FormOption, FormSelect, Modal } from "../../../ui";
import { Loading } from "../../../ui/components/Loading";
import useCurrentLocation from "../../../utils/hooks/useCurrentLocation";
import { useStoresAtLocation } from "../hooks/useStoresAtLocation";
import { useCreateStoreMenuCategoryPrepStation } from "./hooks/useCreateStoreMenuCategoryPrepStation";
import { useCreateStoreMenuItemPrepStation } from "./hooks/useCreateStoreMenuItemPrepStation";
import { useCreateStoreMenuPrepStation } from "./hooks/useCreateStoreMenuPrepStation";
import { useDeleteStoreMenuCategoryPrepStation } from "./hooks/useDeleteStoreMenuCategoryPrepStation";
import { useDeleteStoreMenuItemPrepStation } from "./hooks/useDeleteStoreMenuItemPrepStation";
import { useDeleteStoreMenuPrepStation } from "./hooks/useDeleteStoreMenuPrepStation";
import { usePrepStations } from "./hooks/usePrepStations";
import { useStoreMenus } from "./hooks/useStoreMenus";

interface Props {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}
export const ConfigurePrepStationModal = ({
  showModal,
  setShowModal,
}: Props) => {
  const currentLocation = useCurrentLocation();

  const { locationSlug } = useParams<{ locationSlug: string }>();
  const stores = useStoresAtLocation(currentLocation?.id);
  const [selectedStoreID, setSelectedStoreID] = useState<number>(0);

  const {
    data: prepStationsData,
    loading: prepStationsLoading,
    refetch: prepStationsRefetch,
  } = usePrepStations(currentLocation?.id);
  const { data: storeMenusData, loading: storeMenusLoading } =
    useStoreMenus(locationSlug);
  // Mutations for changing prep stations
  const createStoreMenuPrepStation = useCreateStoreMenuPrepStation();
  const createStoreMenuCategoryPrepStation =
    useCreateStoreMenuCategoryPrepStation();
  const createStoreMenuItemPrepStation = useCreateStoreMenuItemPrepStation();
  const deleteStoreMenuPrepStation = useDeleteStoreMenuPrepStation();
  const deleteStoreMenuCategoryPrepStation =
    useDeleteStoreMenuCategoryPrepStation();
  const deleteStoreMenuItemPrepStation = useDeleteStoreMenuItemPrepStation();

  // Some derived values to make the code a little cleaner
  const storeMenuPrepStations =
    prepStationsData?.location?.store_menu_prep_stations;
  const storeMenuCategoryPrepStations =
    prepStationsData?.location?.store_menu_category_prep_stations;
  const storeMenuItemPrepStations =
    prepStationsData?.location?.store_menu_item_prep_stations;
  const currentMenu = storeMenusData?.stores.find(
    (store: any) => store.id === selectedStoreID,
  )?.menus[0];
  const currentStoreMenuPrepStations = storeMenuPrepStations?.filter(
    (prep_station: any) =>
      parseInt(prep_station.store_menu_id) === parseInt(currentMenu?.id),
  );

  const currentStoreMenuPrepStationOptions = currentStoreMenuPrepStations?.map(
    (prepStation: any) => ({
      value: prepStation.prep_station.id,
      label: prepStation.prep_station.name,
      storeMenuPrepStationId: prepStation.id,
      level: "menu",
    }),
  );

  const prepStationOptions = useMemo(() => {
    if (prepStationsData) {
      return prepStationsData.location.prep_stations.map(
        (prep_station: any) => ({
          value: prep_station.id,
          label: prep_station.name,
        }),
      );
    }
    return [];
  }, [prepStationsData]);

  useEffect(() => {
    if (stores.length !== 0) {
      setSelectedStoreID(stores[0].id);
    }
  }, [stores]);

  const onMenuPrepStationChange = async (_value: any, actionMeta: any) => {
    if (actionMeta.action === "remove-value") {
      await deleteStoreMenuPrepStation({
        variables: {
          store_menu_prep_station_id:
            actionMeta.removedValue.storeMenuPrepStationId,
        },
      });
    } else if (actionMeta.action === "select-option") {
      await createStoreMenuPrepStation({
        variables: {
          store_menu_id: currentMenu.id,
          prep_station_id: actionMeta.option.value,
        },
      });
    } else if (actionMeta.action === "clear") {
      const promises = actionMeta.removedValues.map(
        async (removedValue: any) => {
          await deleteStoreMenuPrepStation({
            variables: {
              store_menu_prep_station_id: removedValue.storeMenuPrepStationId,
            },
          });
        },
      );
      await Promise.all(promises);
    }
    await prepStationsRefetch();
  };
  const onMenuCategoryPrepStationChange = async (
    _value: any,
    actionMeta: any,
    menuCategory: any,
  ) => {
    if (actionMeta.action === "remove-value") {
      await deleteStoreMenuCategoryPrepStation({
        variables: {
          store_menu_category_prep_station_id:
            actionMeta.removedValue.storeMenuCategoryPrepStationId,
        },
      });
    } else if (actionMeta.action === "select-option") {
      await createStoreMenuCategoryPrepStation({
        variables: {
          store_menu_category_id: menuCategory.id,
          prep_station_id: actionMeta.option.value,
        },
      });
    } else if (actionMeta.action === "clear") {
      const promises = actionMeta.removedValues.map(
        async (removedValue: any) => {
          await deleteStoreMenuCategoryPrepStation({
            variables: {
              store_menu_category_prep_station_id:
                removedValue.storeMenuCategoryPrepStationId,
            },
          });
        },
      );
      await Promise.all(promises);
    }
    await prepStationsRefetch();
  };
  const onMenuItemPrepStationChange = async (
    _value: any,
    actionMeta: any,
    menuItem: any,
  ) => {
    if (actionMeta.action === "remove-value") {
      await deleteStoreMenuItemPrepStation({
        variables: {
          store_menu_item_prep_station_id:
            actionMeta.removedValue.storeMenuItemPrepStationId,
        },
      });
    } else if (actionMeta.action === "select-option") {
      await createStoreMenuItemPrepStation({
        variables: {
          store_menu_item_id: menuItem.id,
          prep_station_id: actionMeta.option.value,
        },
      });
    } else if (actionMeta.action === "clear") {
      const promises = actionMeta.removedValues.map(
        async (removedValue: any) => {
          await deleteStoreMenuItemPrepStation({
            variables: {
              store_menu_item_prep_station_id:
                removedValue.storeMenuItemPrepStationId,
            },
          });
        },
      );
      await Promise.all(promises);
    }
    await prepStationsRefetch();
  };

  const colorStyles = {
    multiValue: (styles: any, { data }: any) => {
      let backgroundColor;

      if (data.level === "menu") {
        backgroundColor = "#FEF3C7";
      } else if (data.level === "category") {
        backgroundColor = "#E0E7FF";
      } else if (data.level === "item") {
        backgroundColor = "#FCE7F3";
      }

      return {
        ...styles,
        backgroundColor,
      };
    },
  };

  if (
    storeMenusLoading ||
    prepStationsLoading ||
    !selectedStoreID ||
    !currentMenu?.menu_categories
  ) {
    return (
      <Modal
        title="Manage Prep Station"
        showModal={showModal}
        setShowModal={setShowModal}
      >
        <Loading />
      </Modal>
    );
  }
  return (
    <Modal
      title="Manage Prep Station"
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <div className="my-5">
        <form>
          <div className="grid grid-cols-3 gap-6">
            <div>
              <FormLabel title="Store" htmlFor="store_id" />
              <FormSelect
                value={selectedStoreID}
                onChange={(e) => setSelectedStoreID(parseInt(e.target.value))}
              >
                {!!stores &&
                  stores.map((store: any, index: number) => {
                    return (
                      <FormOption title={store.brand.name} value={store.id} />
                    );
                  })}
              </FormSelect>
            </div>
            <div className="col-span-2">
              <FormLabel title="Menu Level" htmlFor="" />
              {/* When this dropdown changes, all values below will also change */}
              <Select
                value={currentStoreMenuPrepStationOptions}
                styles={colorStyles}
                onChange={onMenuPrepStationChange}
                options={prepStationOptions}
                isMulti
                isSearchable={true}
              />
            </div>
          </div>
          <div>
            {currentMenu?.menu_categories &&
              currentMenu?.menu_categories?.map(
                (menuCategory: any, menuCategoryIndex: number) => {
                  const currentStoreMenuCategoryPrepStations =
                    storeMenuCategoryPrepStations
                      .filter(
                        (prepStation: any) =>
                          prepStation.store_menu_category_id ===
                          menuCategory.id,
                      )
                      .map((prepStation: any) => ({
                        value: prepStation.prep_station.id,
                        label: prepStation.prep_station.name,
                        storeMenuCategoryPrepStationId: prepStation.id,
                        level: "category",
                      }));
                  const categoryDisplay =
                    currentStoreMenuCategoryPrepStations.length > 0
                      ? currentStoreMenuCategoryPrepStations
                      : currentStoreMenuPrepStationOptions;

                  return (
                    <>
                      <div className="grid grid-cols-3 gap-6 my-5">
                        <div>
                          <p className="font-bold">{menuCategory.name}</p>
                        </div>
                        <div className="col-span-2">
                          <Select
                            value={categoryDisplay}
                            styles={colorStyles}
                            options={prepStationOptions}
                            isMulti
                            onChange={(value, actionMeta) =>
                              onMenuCategoryPrepStationChange(
                                value,
                                actionMeta,
                                menuCategory,
                              )
                            }
                            isSearchable={true}
                          />
                        </div>
                      </div>
                      {menuCategory?.menu_items.map((menuItem: any) => {
                        const currentStoreMenuItemPrepStations =
                          storeMenuItemPrepStations
                            .filter(
                              (prepStation: any) =>
                                prepStation.store_menu_item_id === menuItem.id,
                            )
                            .map((prepStation: any) => ({
                              value: prepStation.prep_station.id,
                              label: prepStation.prep_station.name,
                              storeMenuItemPrepStationId: prepStation.id,
                              level: "item",
                            }));
                        const menuItemDisplay =
                          currentStoreMenuItemPrepStations.length > 0
                            ? currentStoreMenuItemPrepStations
                            : currentStoreMenuCategoryPrepStations.length > 0
                            ? currentStoreMenuCategoryPrepStations
                            : currentStoreMenuPrepStationOptions;
                        return (
                          <div className="grid grid-cols-3 gap-6 my-5">
                            <div>
                              <p>{menuItem.name}</p>
                            </div>
                            <div className="col-span-2">
                              <Select
                                value={menuItemDisplay}
                                styles={colorStyles}
                                options={prepStationOptions}
                                onChange={(value, actionMeta) =>
                                  onMenuItemPrepStationChange(
                                    value,
                                    actionMeta,
                                    menuItem,
                                  )
                                }
                                isMulti
                                isSearchable={true}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </>
                  );
                },
              )}
          </div>
        </form>
      </div>
    </Modal>
  );
};
