import { useMutation, gql } from "@apollo/client";

const UPDATE_LOCATION_QUERY = gql`
  mutation UPDATE_LOCATION_QUERY($input: UpdateLocationInput) {
    update_location(input: $input)
  }
`;

export const useUpdateLocation = () => {
  const [updateLocation] = useMutation(UPDATE_LOCATION_QUERY);

  return updateLocation;
};
