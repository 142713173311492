import { gql, useMutation } from "@apollo/client";
import { useContext } from "react";
import ToastContext from "../../../../utils/contexts/ToastContext";

const SYNC_REVENUE_CENTERS_QUERY = gql`
  mutation SYNC_REVENUE_CENTERS_QUERY($location_id: Int) {
    sync_revenue_centers(location_id: $location_id) {
      location_id
      location_name
      msg
      success
    }
  }
`;

export const useSyncRevenueCenters = () => {
  const { showToast } = useContext(ToastContext);

  const [syncRevenueCenters, { loading, error }] = useMutation(
    SYNC_REVENUE_CENTERS_QUERY,
  );

  const onSyncRevenueCenters = async ({
    location_id,
  }: {
    location_id: string | null;
  }) => {
    try {
      const { data } = await syncRevenueCenters({
        variables: {
          location_id,
        },
      });

      const failedLocationNames = data.sync_revenue_centers
        .filter((result: { success: boolean }) => !result.success)
        .map(
          (failedSync: { location_name: string }) => failedSync.location_name,
        )
        .join(", ");

      showToast({
        description: failedLocationNames
          ? `Following locations failed to sync: ${failedLocationNames}`
          : "Syncing of Revenue Centers is complete.",
        seconds: 8,
        variant: "success",
      });
    } catch (err) {
      showToast({
        description: "Error occurred while syncing revenue centers",
        variant: "error",
        seconds: 4,
      });
    }
  };
  return { onSyncRevenueCenters, loading, error };
};
