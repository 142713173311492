import React, { useState } from "react";
import { useOperationsBrands } from "../KitchenNames/hooks/useOperationsBrands";
import { useLocations } from "../Orders/hooks/useLocations";
import { useAllStores } from "./hooks/useAllStores";
import useSyncEvents from "./hooks/useSyncEvents";
import { Menus } from "./Menus";

export const MenusController = () => {
  const { data: storesData, loading: storesLoading } = useAllStores();
  const { data: locationsData, loading: locationsLoading } = useLocations();
  const { data: syncEventData, loading: syncDataLoading } = useSyncEvents();
  const { data: operationsBrandsData, loading: operationsBrandsLoading } =
    useOperationsBrands();

  return (
    <Menus
      stores={storesData?.all_stores}
      locations={locationsData?.locations}
      syncEvents={syncEventData?.menu_sync_events}
      operationBrands={operationsBrandsData?.operations_brands}
      loading={
        storesLoading ||
        locationsLoading ||
        syncDataLoading ||
        operationsBrandsLoading
      }
    />
  );
};
